<template>
    <div>
        <SimpleForm :fieldsets="fieldsets" :errors="errors" :allowMutation="true">{{$t('pages.login.form.title')}}</SimpleForm>
    </div>
</template>

<script>

import LabelledInput from "../../../common/components/inputs/LabelledInput";
import useVuelidate from "@vuelidate/core";
import RegistrationButton from "../../../common/components/buttons/navigation/Registration";
import SimpleForm from "../../../common/components/Form/SimpleForm";
import {Checkbox, Password, Submit, Text} from "../../../common/classes/Models/Form/Inputs";
import {computed, ref} from "vue";

export default {
    name: "FrameLogin",
    components: {SimpleForm, RegistrationButton, LabelledInput},

    data() {return {
        v$: useVuelidate(),
        email: new Text({
            label: 'pages.login.form.email',
            value: null
        }),
        password: new Password({
            label: 'pages.login.form.password',
            value: null
        }),
        rememberMe: new Checkbox({
            label: 'pages.login.form.rememberMe',
            value: false
        }),
        submit: new Submit({
            label: 'pages.login.form.submit',
            action: this.attemptLogin.bind(this),
            disabled: computed(() => {
                return this.disableSubmit || !(this.email.value?.length && this.password.value?.length);
            })
        }),
        disableSubmit: false,
        errors: ref(null)
    }},

    validations() {return {

    };},

    computed: {
        fieldsets() {return [{
            inputs: [
                this.email,
                this.password,
                this.rememberMe,
            ]}, {
            inputs: [
                this.submit
            ]
        }]}
    },

    methods: {
        attemptLogin() {
            this.disableSubmit = true;

            axios.post(frontendConfiguration.urls.login, {
                email: this.email.value,
                password: this.password.value,
                remember: this.rememberMe.value
            })
                .then((response) => {
                    if(response.data.accepted) {
                        location.replace(response.data.relocate);
                    }

                    this.errors = response.data.errors;
                })
                .catch((error) => {
                    console.error(error);
                    let data = error.response.data.errors;
                    let list = [];
                    for(let key in data) {
                        data[key].forEach((item) => {
                            list.push(item);
                        });
                    }

                    this.errors = list;
                })
                .finally(() => {
                    this.disableSubmit = false;
                });
        }
    }
}
</script>
