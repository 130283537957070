<template>
    <div>
        <LabelledSelect :options="options"
                        :translate="false"
                        :placeholderVisible="false"
                        @changed="changeTheme"></LabelledSelect>
    </div>
</template>

<script>
import LabelledSelect from "./select/LabelledSelect.vue";
import {useThemesStore} from "../../stores/Themes";
export default {
    name: "ThemeSelector",

    components: {LabelledSelect},

    data() {return {
        options: [],
        store: null
    };},

    mounted() {
        this.store = useThemesStore();

        _.forOwn(this.store.allowed, (label) => {
            this.options.push({
                value: label,
                token: this.$t('colors.' + label),
                selected: label === this.store.current
            });
        });
    },

    methods: {
        changeTheme(option) {
            this.store.setTheme(option.value);
        }
    }
}
</script>
