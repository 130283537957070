<template>
    <div>
        <div>{{ resource.data.author.data.name }}</div>
        <div>{{ resource.data.content }}</div>
        <div>{{ $formatter.date(resource.data.created_at) }}</div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "Entry",

    props: {
        resource: {
            required: true,
            type: Object
        }
    }
})
</script>
