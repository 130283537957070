<template>
    <form novalidate @submit.prevent>
        <Fieldset v-for="fieldset in fieldsets" :inputs="fieldset.inputs" :allowMutation="allowMutation" @update:input="updateInput">
            <template #legend v-if="fieldset.legend">{{fieldset.legend}}</template>
        </Fieldset>
        <ErrorFieldset v-show="errors" :errors="errors">
            <template #legend>{{$t('html.forms.errors')}}</template>
        </ErrorFieldset>
    </form>
</template>

<script>
import Fieldset from "./Fieldset.vue";
import ErrorFieldset from "./ErrorFieldset.vue";
export default {
    name: "SimpleForm",

    components: {ErrorFieldset, Fieldset},

    props: {
        fieldsets: {
            required: true,
            type: Object,
            validator: function (prop) {
                let valid = true;

                _.forOwn(prop, (item, index) => {
                    if(!item.hasOwnProperty('inputs')) {
                        valid = false;
                        console.warn('Missing "inputs" field at index ' + index, item);
                    }
                });

                return valid;
            }
        },

        errors: {
            required: false,
            type: Array,
            default: null
        },

        allowMutation: {
            required: false,
            type: Boolean,
            default: false
        }
    },

    methods: {
        updateInput(data) {
            this.$emit('update:input', data);
        }
    },
}
</script>
