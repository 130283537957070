<template>
	<div>
		<span>Unauthorized (401)</span>
	</div>
</template>

<script>
	export default {
		mounted() {
			console.log('401 mounted.')
		}
	}
</script>
