<template>
    <div class="page-frame">
        <div v-if="Guild">
            <div>{{Guild.data.name}}</div>
            <div>
                <router-link class="-button" :to="{name: 'guild.view'}">View</router-link>
                <router-link class="-button" v-if="Guild.meta.isOwner" :to="{name: 'guild.manage'}">Manage</router-link>
                <router-link class="-button" v-if="Guild.meta.isMember" :to="{name: 'guild.members'}">Members</router-link>
                <router-link class="-button" v-if="Guild.meta.isOwner" :to="{name: 'guild.manage.applicants'}">Applicants</router-link>
                <router-link class="-button" :to="{name: 'guild.timeline'}">Timeline</router-link>
            </div>
            <router-view @membership-status="membershipStatusHandler"></router-view>
        </div>
        <div v-else>Nothing found...</div>
    </div>
</template>

<script>

import {computed} from "vue";
import {useModelsStore} from "../../../common/stores/Models";

const ModelsStore = useModelsStore();
const queryGuild = async function(uuid) {
    try {
        let Response = await axios.get([window.frontendConfiguration.urls.api.resources.guilds, uuid].join('/'), {
            params: {
                load: ['users'],
            }
        });

        ModelsStore.set(Response.data);
    }
    catch (error) {
        console.error('Fetch error', error);
    }
};
let loading = false;

const beforeRouteAction = function(to, from, next) {
    loading = true;

    if(!ModelsStore.has(to.params.uuid)) {
        queryGuild(to.params.uuid).then(() => {
            loading = false;
        }).finally(() => {
            next();
        });

        return; // Making sure next() is called once!
    }

    next();
};

export default {
    /*data: function() {return {

    };},*/

    provide() {return {
        Guild: computed(() => this.Guild),
        loading: computed(() => loading)
    };},

    beforeRouteEnter(to, from, next) {
        return beforeRouteAction(to, from, next);
    },

    beforeRouteUpdate(to, from, next) {
        return beforeRouteAction(to, from, next);
    },

    computed: {
        Guild() {
            if(!ModelsStore.has(this.$route.params.uuid)) {
                queryGuild(this.$route.params.uuid);
            }

            return ModelsStore.get(this.$route.params.uuid);
        },

        loading() {
            return loading;
        }
    },

    methods: {
        membershipStatusHandler(decision, User) {
            switch(decision) {
                case 'accept':
                    this.Guild.data.meta.members[User.data.uuid] = User;
                    // fallthrough
                case 'reject':
                    delete(this.Guild.data.meta.membershipRequestedBy[User.data.uuid]);
                    break;
                case 'discharge':
                    delete(this.Guild.data.meta.members[User.data.uuid]);
                    break;
            }
        }
    }
}
</script>
