import {RedirectRoute, Route} from "../common/classes/RouteTypes";

export default [
    // Errors
    new Route({path: '/404', name: 'http.status.404', component: () => import(/* webpackMode: 'eager' */ '../common/pages/errors/E_404'), hidden: true }),
    new Route({path: '/403', name: 'http.status.403', component: () => import(/* webpackMode: 'eager' */ '../common/pages/errors/E_403'), hidden: true }),
    new Route({path: '/401', name: 'http.status.401', component: () => import(/* webpackMode: 'eager' */ '../common/pages/errors/E_401'), hidden: true }),

    // Otherwise
    new Route({path: '/:pathMatch(.*)*', component: () => import(/* webpackMode: 'eager' */ '../common/pages/errors/E_404'), hidden: true })
]
