<template>
    <div>
        <div v-if="User">
            <div>{{User.data.name}}</div>
            <div>{{User.data.uuid}}</div>
        </div>
        <div v-else>Nothing found</div>
    </div>
</template>

<script>
export default {
    data: function() {return {

    };},

    inject: ['User'],

    methods: {

    }
}
</script>
