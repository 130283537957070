<template>
    <div class="page-guild-manage">
        <div>
            <div>uuid: {{Guild.data.uuid}}</div>
        </div>
        <div>
            <labelled-input v-model="GuildClone.data.name" name="name" type="text">Name</labelled-input>
            <button @click="save()">Save</button>
        </div>
    </div>
</template>

<script>
import LabelledInput from "../../../common/components/inputs/LabelledInput.vue";
import {useModelsStore} from "../../../common/stores/Models";

export default {
    components: {LabelledInput},
    inject: ['Guild'],

    data: function() {return {
        GuildClone: _.cloneDeep(this.Guild)
    };},

    methods: {
        save() {
            axios.patch([frontendConfig.urls.api.resources.guilds, this.Guild.uuid].join('/'), this.GuildClone)
                .then((response) => {
                    let User = _.cloneDeep(response.data.data);
                    const ModelsStore = useModelsStore();
                    ModelsStore.set(User);
                })
                .catch((error) => {
                    console.error('Fetch error', error);
                });
        }
    }
}
</script>
