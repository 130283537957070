import {useLocalizationStore} from "../stores/Localization";
import {
    format as dateFnsFormat,
    formatDistance as dateFnsFormatDistance
} from 'date-fns';
import { enGB as en, hu } from 'date-fns/locale';

export default class Formatter{
    constructor() {
        this.LocaleStore = useLocalizationStore();
        this.config = {
            formats: {
                date: 'yyyy MMMM d HH:mm:ss'
            },
            locales: {en, hu}
        };
    }

    install(app, options) {
        const exposed = {
            date: this.date.bind(this),
            fromNow: this.fromNow.bind(this),
            filesize: this.filesize.bind(this)
        };

        /**
         * Example:
         * this.$root.$formatter
         */
        app.config.globalProperties.$formatter = exposed;

        /**
         * Example:
         * import { inject } from 'vue';
         *
         * const Formatter = inject('Formatter')
         */
        app.provide('Formatter', exposed);
    }

    _getLocale() {
        return this.config.locales[this.LocaleStore.current];
    }

    _isEmpty(value) {
        return value === 0 || !value;
    }

    date(date, format) {
        if(this._isEmpty(date)) {
            return;
        }

        return dateFnsFormat(new Date(date), format ? format : this.config.formats.date, { locale: this._getLocale() });
    }

    fromNow(date) {
        if(this._isEmpty(date)) {
            return;
        }

        return dateFnsFormatDistance(new Date(date), new Date(), { locale: this._getLocale(), addSuffix: true });
    }

    filesize(bytes, decimals) {
        if(bytes == 0) {
            return '0 b';
        }

        let kilo = 1024,
            decimalsLength = decimals || 2,
            sizes = ['b', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'],
            index = Math.floor(Math.log(bytes) / Math.log(kilo));

        return parseFloat((bytes / Math.pow(kilo, index)).toFixed(decimalsLength)) + ' ' + sizes[index];
    }
};
