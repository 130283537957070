<template>
    <div class="LogoutFrame">
        <div>Kijelentkezés folyamatban! Kérjük várjon...</div>
        <div>{{ error }}</div>
    </div>
</template>

<script>
export default {
    data: function() {return {
        error: null,
        timeoutId: null
    };},

    mounted() {
        this.attemptLogout();
    },

    destroyed() {
        clearTimeout(this.timeoutId);
    },

    methods: {
        attemptLogout: function() {
            const $this = this;

            axios.get(window.frontendConfiguration.urls.logout)
                .then(function (response) {
                    if(response.data.accepted) {
                        location.replace(response.data.relocate);
                        return;
                    }

                    $this.error = response.data.error;

                    $this.timeoutId = setTimeout($this.attemptLogout, 1e3);
                })
                .catch(function (error) {
                    console.log('Fetch Error', error);
                });
        }
    }
}
</script>
