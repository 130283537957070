<template>
    <fieldset>
        <legend v-if="this.$slots.legend"><slot name="legend"></slot></legend>
        <ul>
            <li v-for="error in errors">{{error}}</li>
        </ul>
    </fieldset>
</template>

<script>

export default {
    name: "FormErrorFieldset",

    props: {
        errors: {
            required: false,
            type: Array,
            default: []
        }
    }
}
</script>
