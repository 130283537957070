<template>
    <div>
        <h1>{{ $t('pages.welcome.breadcrumb') }}</h1>
        <div>{{ $t('content') }}</div>
    </div>
</template>

<script>

export default {
    name: "FrameWelcome",

    i18n: {
        messages: {
            en: {
                content: 'There is not much to see yet. By the time may it will worth coming back.'
            },
            hu: {
                content: 'Jelenleg még nincs sok tartalom itt. Azonban idővel remélhetőleg megéri majd visszatérni ide.'
            },
        }
    }
}
</script>
