import { defineStore } from 'pinia';
import Cookies from 'js-cookie';

export const useThemesStore = defineStore('Stores.Themes', {
    state: () => ({
        cookieName: 'Application-Styling-Theme',
        current: Cookies.get('Application-Styling-Theme'),
        history: [],
        allowed: ['light', 'dark', 'red', 'blue'], // Must be compatible with the generated themes list in the app.scss file
        fallback: 0, // index of this.allowed
        initialize: true
    }),

    actions: {
        setTheme(name) {
            if(!this.allowed.includes(name)) {
                console.error(`Theme key "${name}" is not allowed! Setting fallback value: "${this.getFallback()}"`);
                name = this.allowed[this.fallback];
            }

            this.history.push(this.current);
            this.current = name;

            // Set cookie
            Cookies.set(this.cookieName, this.current, {expires: 365});
            axios.defaults.headers.common[this.cookieName] = this.current;

            return this;
        },

        initialize() {
            if(!this.current || !this.allowed.includes(this.current)) {
                this.setTheme(this.getFallback());
            }

            this.initialize = false;
        },

        getFallback() {
            return this.allowed[this.fallback];
        },

        getHtmlClass() {
            return 'theme-color theme-color-' + this.current;
        }
    }
});
