<template>
    <div>
        <SimpleForm :fieldsets="fieldsets" :errors="errors" :allowMutation="true">{{$t('pages.login.form.title')}}</SimpleForm>
    </div>
</template>

<script>
import SimpleForm from "../../../common/components/Form/SimpleForm";
import {Text, Password, Submit, Checkbox} from "../../../common/classes/Models/Form/Inputs";
import {computed, ref} from "vue";
import LoginButton from "../../../common/components/buttons/navigation/Login";

export default {
    name: "RegistrationUser",
    components: {LoginButton, SimpleForm},

    data() {return {
        name: new Text({
            label: 'pages.login.form.name',
            value: null
        }),
        email: new Text({
            label: 'pages.login.form.email',
            value: null
        }),
        password: new Password({
            label: 'pages.login.form.password',
            value: null
        }),
        passwordConfirm: new Password({
            label: 'pages.login.form.passwordConfirm',
            value: null
        }),
        termsOfUseAccepted: new Checkbox({
            label: 'pages.login.form.TermsOfUse',
            value: false
        }),
        submit: new Submit({
            label: 'pages.login.form.submit',
            action: this.submitForm.bind(this),
            disabled: computed(() => {
                return this.disableSubmit || !(this.email.value?.length &&
                    this.password.value?.length &&
                    this.passwordConfirm.value?.length &&
                    (this.password.value === this.passwordConfirm.value) &&
                    this.termsOfUseAccepted.value);
            })
        }),
        disableSubmit: false,
        errors: ref(null)
    };},

    computed: {
        fieldsets() {return [{
            inputs: [
                this.name,
                this.email,
                this.password,
                this.passwordConfirm,
                this.termsOfUseAccepted,
            ]}, {
            inputs: [
                this.submit
            ]
        }]}
    },

    methods: {
        storeServerErrors(errors) {console.log(errors);
            let list = [];
            for(let key in errors) {
                errors[key].forEach((item) => {
                    list.push(item);
                });
            }

            this.errors = list;
        },

        submitForm() {
            this.disableSubmit = true;

            let data = {
                name: this.name,
                email: this.email,
                password: this.password,
                termsOfUse: this.termsOfUseAccepted,
            };

            axios.post(frontendConfiguration.urls.registration, data)
                .then((response) => {
                    if(response.data.accepted) {
                        this.$router.replace({path: response.data.data});

                        return;
                    }

                    if(response.data.errors) {
                        this.storeServerErrors(response.data.errors);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.storeServerErrors(error.response.data.errors);
                })
                .finally(() => {
                    this.disableSubmit = false;
                });
        }
    }
}
</script>
