<template>
    <div class="labelled-input">
        <label>
            <div class="labelled-input__input-title"><slot></slot></div>
            <div class="_grow-wrap" :data-replicated-value="modelValue">
                <textarea :name="name" :value="modelValue" :class="classObject"
                    @input="onChange"
                    @change="onChange"
                    :disabled="disabled"></textarea>
            </div>
        </label>
        <aside v-if="hasError" class="labelled-input__errors">
            <ul>
                <li v-for="error in validator.$errors">{{ error.$message }}</li>
            </ul>
        </aside>
    </div>
</template>

<script>
    import { defineComponent } from "vue";

    export default defineComponent({
        name: 'LabelledTextarea',
        props: {
            name: {
                type: String,
                required: false,
            },
            modelValue: {
                required: true,
            },
            validator: {
                required: false,
                default: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },

        data() {return {
            value: this.modelValue
        };},

        computed: {
            classObject() {
                return {
                    '-pristine': this.validator && !this.validator.$dirty,
                    '-error': this.validator.$dirty && this.hasError,
                    '-correct': this.validator.$dirty && !this.hasError,
                };
            },

            hasError() {
                return this.validator && this.validator.$dirty && this.validator.$errors.length > 0;
            }
        },

        methods: {
            onChange(event) {
                this.emitNewValue(event);

                this.validator && this.validator.$validate();
            },

            emitNewValue(event) {
                this.$emit('changed', event.target.value);
                this.$emit('update:modelValue', event.target.value);
            }
        },
    })
</script>

<style lang="scss" scoped>
// CSS will handle the autogrowing effect with the grid system.
// Further styling is in the scss files!
._grow-wrap {
    display: grid;

    &:after {
        content: attr(data-replicated-value) " ";
        white-space: pre-wrap;
        visibility: hidden;
    }
    
    & > textarea {
        resize: none;
        overflow: hidden;
    }

    & > textarea,
    &::after {
        font: inherit;
        grid-area: 1 / 1 / 2 / 2;
    }
}
</style>
