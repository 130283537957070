export default {
    pages: {
        welcome: {
            breadcrumb: 'Welcome'
        },
        index: {
            breadcrumb: 'Homepage'
        },
        login: {
            breadcrumb: 'Login'
        },
        registration: {
            breadcrumb: 'Registration'
        }
    }
};
