<template>
    <div class="page-frame-timeline">
        <div>
            <SimpleForm :fieldsets="newEntryFormFieldsets" :allow-mutation="true"/>
        </div>
        <div>
            <Entry v-for="entry in entries" :resource="entry"/>
        </div>
    </div>
</template>

<script>

import SimpleForm from "../../../common/components/Form/SimpleForm.vue";
import {Submit, Textarea} from "../../../common/classes/Models/Form/Inputs";
import {computed} from "vue";
import Entry from "./components/Entry.vue";

export default {
    components: {Entry, SimpleForm},

    inject: ['UserProvider'],

    data: function() {return {
        entries: [],
        newEntryContent: new Textarea({
            label: 'content',
            value: null
        }),
        newEntrySubmit: new Submit({
            label: 'submit',
            action: this.createNewEntry.bind(this),
            disabled: computed(() => {
                return this.sending;
            })
        }),
        sending: false
    };},

    mounted() {
        this.queryTimeline();
    },

    computed: {
        newEntryFormFieldsets() {return [{
            inputs: [
                this.newEntryContent
            ]}, {
            inputs: [
                this.newEntrySubmit
            ]
        }]}
    },

    methods: {
        queryTimeline() {
            axios.get(window.frontendConfiguration.urls.api.resources.timeline)
                .then((response) => {
                    _.forOwn(response.data.data, (item) => {
                        this.entries.push(item);
                    })
                });
        },

        createNewEntry() {
            this.sending = true;

            let data = {
                content: this.newEntryContent.value,
                author_uuid: this.UserProvider.data.uuid,
                guild_uuid: null,
                visibility: 'public'
            };

            axios.post(window.frontendConfiguration.urls.api.resources.timeline, data)
                .then((response) => {
                    this.newEntryContent.value = null;
                    this.entries.unshift(response.data);
                })
                .finally(() => {
                    this.sending = false;
                });
        }
    }
}
</script>
