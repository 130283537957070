<template>
    <header>
        <button id="menu-toggle-navbar" @click="NavigationMenu.toggle()" title="Navigation">
            <Icon aria-label="Menu toggle icon" icon="menu">Navigation</Icon>
        </button>
        <div v-if="$user.isLoggedIn()" id="header-user">
            <router-link-user :user="$user"/>
        </div>
        <div v-else id="header-user"><router-link to="/">Reenactment</router-link></div>
        <button id="menu-toggle-settings" @click="SettingsMenu.toggle()" title="Settings">
            <Icon aria-label="Settings toggle icon" icon="cog">Settings</Icon>
        </button>
    </header>
</template>

<script>
import RouterLinkUser from "./links/RouterLinkUser.vue";
import LocalizationSelector from "./inputs/LocalizationSelector.vue";
import ThemeSelector from "./inputs/ThemeSelector.vue";
import Icon from "../components/Icon.vue";

export default {
    name: "Header",

    components: {ThemeSelector, RouterLinkUser, LocalizationSelector, Icon},

    inject: ['NavigationMenu', 'SettingsMenu']
}
</script>
