<template>
    <div class="page-guild-view">
        <div>
            <div>{{Guild.data.name}}</div>
            <div>{{Guild.data.uuid}}</div>
            <div v-if="$user.isLoggedIn()">
                <button v-if="Guild.meta.canRequestMembership" @click="joinGuild()">request membership</button>
                <div class="d-inline" v-if="Guild.meta.membershipRequestExists">Membership request sent <button @click="cancelMembershipRequest()">Cancel</button></div>
                <button v-if="Guild.meta.isMember && !Guild.meta.isOwner" @click="leaveGuild()">leave</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    inject: ['Guild'],

    methods: {
        joinGuild() {
            let url = this.Guild.meta.urls.membership.join.replace(':guild_uuid', this.Guild.uuid);
            axios.get(url)
                .then((response) => {
                    if(response.data.accepted) {
                        let meta = _.clone(this.Guild.meta);

                        meta.membershipRequestExists = true;
                        meta.canRequestMembership = false;

                        this.Guild.meta = meta;
                    }
                })
                .catch((error) => {
                    console.error('Fetch error', error);
                });
        },

        cancelMembershipRequest() {
            let url = this.Guild.meta.urls.membership.cancelRequest.replace(':guild_uuid', this.Guild.uuid);
            axios.get(url)
                .then((response) => {
                    if(response.data.accepted) {
                        let meta = _.clone(this.Guild.meta);

                        meta.membershipRequestExists = false;
                        meta.canRequestMembership = true;

                        this.Guild.meta = meta;
                    }
                })
                .catch((error) => {
                    console.error('Fetch error', error);
                });
        },

        leaveGuild() {
            let url = this.Guild.meta.urls.membership.leave.replace(':guild_uuid', this.Guild.uuid);

            axios.get(url)
                .then((response) => {
                    if(response.data.accepted) {
                        let meta = _.clone(this.Guild.meta);

                        meta.membershipRequestExists = false;
                        meta.canRequestMembership = true;
                        meta.isMember = false;

                        this.Guild.meta = meta;
                    }
                })
                .catch((error) => {
                    console.error('Fetch error', error);
                });
        },
    }
}
</script>
