import './bootstrap';

import { createApp } from 'vue/dist/vue.esm-bundler';
import * as VueRouter from 'vue-router';
import { createPinia } from 'pinia';
import {useThemesStore} from "./stores/Themes";
import {useLocalizationStore} from "./stores/Localization";
import User from "./plugins/User";
import Formatter from "./plugins/Formatter";
import MainFrame from "./components/MainFrame.vue";
import {useModelsStore} from "./stores/Models";

// Routes
import common from "../routes/common";
import guest from "../routes/guest";
import authenticated from "../routes/authenticated";

const Application = createApp({
    components: { MainFrame },
    template: '<MainFrame :class="[ThemesStore.getHtmlClass()]"/>',

    data() {return {
        ThemesStore: useThemesStore()
    };},

    beforeMount() {
        this.ThemesStore.initialize();
    }
});

Application
    .use(createPinia());

const UserPlugin = new User();

const Router = new VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes: _.concat(common, UserPlugin.isLoggedIn() ? authenticated : guest)
});

Router.beforeResolve(async (to, from) => {
    let hasPermission = true;
    to.matched.forEach((Route) => {
        if(!hasPermission) {
            return;
        }

        if(Route.meta.permissions.length) {
            hasPermission = UserPlugin.hasPermission(Route.meta.permissions, {route: {to, from}});
        }
    });

    let hasRole = true;
    to.matched.forEach((Route) => {
        if(!hasRole) {
            return;
        }

        if(Route.meta.roles.length) {
            hasRole = UserPlugin.hasRole(Route.meta.roles, {route: {to, from}});
        }
    });

    if(!hasPermission || !hasRole) {
        return {name: 'http.status.403'};
    }

    return true;
});

const LocalizationStore = useLocalizationStore();
const ModelsStore = useModelsStore();

if(UserPlugin.isLoggedIn()) {
    ModelsStore.set(UserPlugin.Model);
}

(async () => {
    Application
        .use(UserPlugin)
        .use(Router)
        .use(await LocalizationStore.getI18nPlugin())
        .use(new Formatter());

    // TODO: Temporary suggestion of VUEJS devs until the next release
    // This will be deprecated at VueJs v3.3
    Application.config.unwrapInjectedRef = true;

    Application.mount('#application');
})();
