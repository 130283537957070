<template>
    <nav class="menu-navigation" @click="closeMenu()">
        <MenuTree/>
        <div v-if="$user.isLoggedIn()">
            <div class="navbar-parent-label">Guild memberships</div>
            <ul>
                <li v-for="Guild in $user.data.memberships.guilds" :class="[Guild.isOwner ? '-owner' : '-member']">
                    <router-link-guild class="navbar-link" :guild="Guild"></router-link-guild>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import RouterLinkGuild from "./links/RouterLinkGuild.vue";
import {defineComponent} from "vue";

const MenuTree = defineComponent({
    name: 'MenuTree',

    template:
        '<ul>' +
            '<li v-for="route in filteredRoutes">' +
                '<div v-if="route.children">' +
                    '<div class="navbar-parent-label">{{$t(route.meta.label)}}</div>' +
                    '<MenuTree :routes="route.children"/>' +
                '</div>' +
                '<router-link v-else class="navbar-link" :to="{name: route.name}">{{ $t(route.meta.label) }}</router-link>' +
            '</li>' +
        '</ul>',

    props: {
        routes: {
            type: Array,
            required: false
        },

        path: {
            type: String,
            required: false,
            default: '',
        }
    },

    computed: {
        filteredRoutes() {
            let routes = this.$props.routes ?? this.$router.options.routes;

            return routes.filter(function(item) {
                return !(item.meta.hidden || false);
            });
        }
    }
});

export default {
    components: {RouterLinkGuild, MenuTree},

    inject: ['NavigationMenu'],

    methods: {
        closeMenu() {
            this.NavigationMenu.close();
        }
    }
}
</script>
