import { defineStore } from 'pinia';
import Cookies from 'js-cookie';
import { createI18n } from 'vue-i18n';

const cookieName = 'Accept-Language';

export const useLocalizationStore = defineStore('Stores.Localization', {
    state: () => ({
        current: Cookies.get(cookieName),
        fallback: 'en',
        history: [],
        allowed: window.frontendConfiguration.localization.allowed,
        i18n: null
    }),

    actions: {
        async setLocale(locale) {
            if(!this.allowed.hasOwnProperty(locale)) {
                console.error(`Localization key "${locale}" is not allowed!`);
                return this;
            }

            if(!this.i18n.global.availableLocales.includes(locale)) {
                let Module = await import(`../localization/${locale}.js`);

                this.i18n.global.setLocaleMessage(locale, Module.default);
            }

            if(locale !== this.current) {
                let history = this.history.slice();
                history.push(this.current);

                this.$patch({
                    current: locale,
                    history: history
                });

                if (this.i18n.mode === 'legacy') {
                    this.i18n.global.locale = locale;
                }
                else {
                    this.i18n.global.locale.value = locale;
                }

                Cookies.set(cookieName, locale);
                document.getElementsByTagName('html')[0].lang = locale;

                //TODO: axios post selected locale
            }

            return this;
        },

        async getI18nPlugin() {
            if(!this.i18n) {
                this.i18n = createI18n({
                    messages: {},
                    locale: this.current,
                    fallbackLocale: this.fallback,
                    silentTranslationWarn: import.meta.env.VITE_VUE_I18N_MISSING_WARNING.toLowerCase() === 'false' ,
                    silentFallbackWarn: import.meta.env.VITE_VUE_I18N_FALLBACK_WARNING.toLowerCase() === 'false',
                    formatFallbackMessages: true
                });

                await this.setLocale(this.current ?? this.fallback);
            }

            return this.i18n;
        }
    }
});
