<template>
    <div class="page-user-manage">
        <div>
            <div>uuid: {{User.data.uuid}}</div>
        </div>
        <div>
            <labelled-input v-model="UserClone.data.name">Name</labelled-input>
            <button @click="save()">Save</button>
        </div>
    </div>
</template>

<script>
import LabelledInput from "../../../common/components/inputs/LabelledInput.vue";

export default {
    components: {LabelledInput},
    inject: ['User'],

    data: function() {return {
        UserClone: _.cloneDeep(this.User)
    };},

    methods: {
        save() {
            axios.patch([window.frontendConfiguration.urls.api.resources.users, this.User.data.uuid].join('/'), this.UserClone)
                .then((response) => {
                    let User = response.data.data;
                    Object.keys(User).forEach((key) => {
                        // TODO: into model store?
                        this.$user.data[key] = User[key];
                    });

                    this.$router.push({name: 'user.view', params: {uuid: this.$user.data.uuid}});
                })
                .catch((error) => {
                    console.error('Fetch error', error);
                });
        }
    }
}
</script>
