<template>
    <div class="page-frame">
        <div v-if="User">
            <div>
                <router-link class="-button" :to="{name: 'user.view'}">View</router-link>
                <router-link class="-button" v-if="canEdit" :to="{name: 'user.manage'}">Manage</router-link>
            </div>
            <router-view/>
        </div>
        <div v-else>Nothing found...</div>
    </div>
</template>

<script>

import {useModelsStore} from "../../../common/stores/Models";
import {computed} from "vue";

const ModelsStore = useModelsStore();
const queryUser = async function(uuid) {
    try {
        let Response = await axios.get([window.frontendConfiguration.urls.api.resources.users, uuid].join('/'), {
            params: {
                load: ['guilds']
            }
        });

        ModelsStore.set(Response.data);
    }
    catch (error) {
        console.error('Fetch error', error);
    }
};

export default {
    /*data: function() {return {

    };},*/

    provide() {return {
        User: computed(() => this.User)
    };},

    computed: {
        canEdit() {
            return this.$user && (this.$user.data.uuid === this.User.data.uuid);
        },

        User() {
            if(!ModelsStore.has(this.$route.params.uuid)) {
                queryUser(this.$route.params.uuid);
            }

            return ModelsStore.get(this.$route.params.uuid);
        }
    }
}
</script>
