<template>
    <div class="frame-main">
        <Header class="frame-main__header"/>
        <div class="frame-main__view-container">
            <BreadcrumbsMenu/>
            <NavigationMenu class="frame-main__view-container__navigation" :class="getClassOf('navigation')"/>
            <SettingsMenu class="frame-main__view-container__settings" :class="getClassOf('settings')"/>
            <router-view class="frame-main__view-container__content"/>
        </div>
    </div>
</template>

<script>
import Header from "./Header.vue";
import BreadcrumbsMenu from "./BreadcrumbsMenu.vue";
import NavigationMenu from "./NavigationMenu.vue";
import SettingsMenu from "./SettingsMenu.vue";

let lastKnownWindowWidth = window.innerWidth;

export default {
    name: "MainFrame",

    components: {SettingsMenu, NavigationMenu, BreadcrumbsMenu, Header},

    data: () => {return {
        menus: {
            navigation: false,
            settings: false
        }
    };},

    beforeMount() {
        window.addEventListener("resize", this.resizeEventHandler);
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.resizeEventHandler);
    },

    provide() {return {
        NavigationMenu: {
            open: () => this.open('navigation'),
            close: () => this.close('navigation'),
            toggle: () => this.toggle('navigation'),
        },

        SettingsMenu: {
            open: () => this.open('settings'),
            close: () => this.close('settings'),
            toggle: () => this.toggle('settings'),
        }
    };},

    methods: {
        getClassOf(key) {return {
            '-opened': this.menus[key],
            '-closed': !this.menus[key]
        };},

        open(key) {
            _.forOwn(this.menus, (value, index) => {
                // Close everything except the required
                this.menus[index] = index === key;
            });

            return this;
        },

        close(key) {
            this.menus[key] = false;

            return this;
        },

        toggle(key) {
            this.menus[key] ?
                this.close(key) :
                this.open(key);

            return this;
        },

        resizeEventHandler() {
            let currentWidth = window.innerWidth;
            const md = 769 // Sass "md" breakpoint

            // Shrinking width and goes below "md"
            if(currentWidth < lastKnownWindowWidth && currentWidth < md) {
                if (this.menus.navigation) {
                    this.close('navigation');
                }

                if (this.menus.settings) {
                    this.close('settings');
                }
            }

            lastKnownWindowWidth = currentWidth;
        }
    },
}
</script>
