<template>
    <div>
        <LocalizationSelector/>
        <ThemeSelector/>
        <LogoutButton v-if="$user.isLoggedIn()"/>
    </div>
</template>

<script>
import LocalizationSelector from "./inputs/LocalizationSelector.vue";
import ThemeSelector from "./inputs/ThemeSelector.vue";
import LogoutButton from "./buttons/navigation/Logout.vue";

export default {
    name: "SettingsMenu",
    components: {ThemeSelector, LocalizationSelector, LogoutButton}
}
</script>

<style scoped>

</style>
