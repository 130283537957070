export default {
    pages: {
        welcome: {
            breadcrumb: 'Köszöntő'
        },
        index: {
            breadcrumb: 'Kezdőlap'
        },
        login: {
            breadcrumb: 'Bejelentkezés'
        },
        registration: {
            breadcrumb: 'Regisztráció'
        }
    }
};
