<template>
    <div class="page-guild-manage-applicants">
        <div>Applicants</div>
        <ul>
            <li v-for="User in Guild.meta.membershipRequestedBy">
                <div><router-link-user :user="User"></router-link-user></div>
                <button @click="accept(User)">Accept</button>
                <button @click="reject(User)">Reject</button>
            </li>
        </ul>
    </div>
</template>

<script>

import RouterLinkUser from "../../../common/components/links/RouterLinkUser.vue";

export default {
    components: {RouterLinkUser},
    inject: ['Guild'],
    emits: ['membershipRequest', 'membershipRequest'],

    data: function() {return {

    };},

    methods: {
        accept(User) {
            let url = this.Guild.meta.urls.membership.accept.replace(':guild_uuid', this.Guild.uuid);

            axios.post(url, {
                user_uuid: User.uuid
            })
                .then((response) => {
                    if(response.data.accepted) {
                        this.$emit('membershipStatus', 'accept', User);
                    }
                })
                .catch(function(error) {
                    console.error('Fetch error', error);
                });
        },

        reject(User) {
            let url = this.Guild.meta.urls.membership.reject.replace(':guild_uuid', this.Guild.uuid);

            axios.post(url, {
                user_uuid: User.uuid
            })
                .then((response) => {
                    if(response.data.accepted) {
                        this.$emit('membershipStatus', 'reject', User);
                    }
                })
                .catch(function(error) {
                    console.error('Fetch error', error);
                });
        }
    }
}
</script>
