<template>
    <router-link :to="{name: 'user.view', params: { uuid: user.data.uuid }}">
        <slot>{{user.data.name}}</slot>
    </router-link>
</template>

<script>

export default {
    name: "RouterLinkUser",

    props: {
        user: {
            type: Object,
            required: true
        }
    }
}
</script>
