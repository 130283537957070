<template>
    <div>
        <LabelledSelect :options="options"
                        :translate="false"
                        :placeholderVisible="false"
                        @changed="changeLocalization"></LabelledSelect>
    </div>
</template>

<script>
import LabelledSelect from "./select/LabelledSelect.vue";
import {useLocalizationStore} from "../../stores/Localization";
export default {
    name: "LocalizationSelector",

    components: {LabelledSelect},

    data() {return {
        options: [],
        store: null
    };},

    mounted() {
        this.store = useLocalizationStore();

        _.forOwn(this.store.allowed, (label, key) => {
            this.options.push({
                value: key,
                token: label,
                selected: key === this.store.current
            });
        });
    },

    methods: {
        changeLocalization(option) {
            this.store.setLocale(option.value);
        }
    }
}
</script>
