<template>
	<div>
		<span>Page not found (404)</span>
	</div>
</template>

<script>
	export default {
		mounted() {
			console.log('404 mounted.')
		}
	}
</script>
