<template>
    <fieldset>
        <legend v-if="this.$slots.legend"><slot name="legend"></slot></legend>
        <component v-for="(input, index) in inputs" :is="input.constructor.name"
                   :modelValue="input.value"
                   :type="input.type"
                   :disabled="input.disabled"
                   :label="input.label"
                   :action="input.action"
                   v-show="!input.hidden"
                   @update:modelValue="updateInput($event, input, index)">
            <template #default v-if="input.label">{{$t(input.label)}}</template>
        </component>
    </fieldset>
</template>

<script>
import * as Inputs from "../../classes/Models/Form/Inputs";
import LabelledInput from "../inputs/LabelledInput";
import Submit from "../inputs/Submit.vue";
import LabelledTextarea from "../inputs/LabelledTextarea";

export default {
    name: "FormFieldset",

    components: {
        [Inputs.Text.name]: LabelledInput,
        [Inputs.Checkbox.name]: LabelledInput,
        [Inputs.Password.name]: LabelledInput,
        [Inputs.Submit.name]: Submit,
        [Inputs.Textarea.name]: LabelledTextarea,
    },

    props: {
        inputs: {
            required: true,
            type: Array
        },

        allowMutation: {
            required: false,
            type: Boolean,
            default: false
        }
    },

    methods: {
        updateInput(newValue, Input, index) {
            if(this.allowMutation) {
                Input.value = newValue;
            }

            this.$emit('update:input', {
                [index]: {input: Input, newValue: newValue}}
            );
        },
    }
}
</script>
