import {RedirectRoute, Route} from "../common/classes/RouteTypes";
import User from "../common/plugins/User";

const UserPlugin = new User();

export default UserPlugin.Model ? [] : [
    new RedirectRoute({path: '/', name: 'index', redirect: {name: 'welcome'}, hidden: true}),

    new Route({path: '/welcome', label: 'pages.welcome.breadcrumb', name: 'welcome',
        component: () => import('../guest/pages/welcome/Frame')}),

    new Route({path: '/login', label: 'pages.login.breadcrumb', name: 'login',
        component: () => import('../guest/pages/login/Frame')}),

    new Route({path: '/registration', label: 'pages.registration.breadcrumb',
        component: () => import('../guest/pages/registration/Frame'),
        children: [
            new Route({
                path: '', label: 'pages.registration.new', name: 'registration.user',
                component: () => import('../guest/pages/registration/User.vue')
            })
        ]}),
];
