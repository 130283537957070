class Input {
    constructor(props, validTypes) {
        this.validTypes = validTypes || ['text', 'checkbox', 'password', 'submit'];

        if(!props.type || !this.validTypes.includes(props.type)) {
            this.invalidInputTypeError(props.type);
        }

        this.type = props.type || this.validTypes[0];

        this.label = props.label || null;
        this.hidden = props.hidden || false;
        this.disabled = props.disabled || false;

        this.value = props.value;
    }

    invalidInputTypeError = (type) => {
        throw 'Invalid form input type: (' + typeof type + ') ' + type;
    };

    toPrimitive() {
        return this.value;
    };
}

Input.prototype.toJSON = function() {
    return this.toPrimitive();
};

Input.prototype.toString = function() {
    return this.toPrimitive();
};

Input.prototype.valueOf = function() {
    return this.toPrimitive();
};

export class Text extends Input {
    constructor(props) {
        if(!props.type) {
            props.type = 'text';
        }

        super(props, ['text', 'password']);
    }
}

export class Password extends Text {
    constructor(props) {
        props.type = 'password';

        super(props, ['password', 'text']);
    }

    showPassword() {
        this.type='text';

        return this;
    }

    hidePassword() {
        this.type='password';

        return this;
    }

    togglePassword() {
        this.type === 'password' ?
            this.showPassword() :
            this.hidePassword();

        return this;
    }
}

export class Checkbox extends Input {
    constructor(props) {
        props.type = 'checkbox';

        super(props, ['checkbox']);
    }
}

export class Submit extends Input {
    constructor(props) {
        props.type = 'submit';

        super(props, ['submit']);

        this.action = props.action;
    }
}

export class Textarea extends Input {
    constructor(props) {
        props.type = 'textarea';

        super(props, ['textarea']);
    }
}
