import { defineStore } from 'pinia';

export const useModelsStore = defineStore('Stores.Models', {
    state: () => ({
        models: {}
    }),

    actions: {
        /**
         *
         * @param Model
         * @returns Proxy Model
         */
        set(Model) {
            // TODO: Broadcastchannel
            Model = _.cloneDeep(Model);

            this.$patch({
                models: {[Model.data.uuid]: Model}
            });

            return this.get(Model.data.uuid);
        },

        /**
         * @param uuid
         * @returns {*|null}
         */
        get(uuid) {
            return this.models[uuid] ?? null;
        },

        /**
         * @param uuid
         * @returns {boolean}
         */
        has(uuid) {
            return this.models.hasOwnProperty(uuid);
        }
    }
});
