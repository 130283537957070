<template>
    <div class="labelled-input">
        <label>
            <div v-if="this.$slots.default">
                <slot></slot>
            </div>
            <select @change="changed($event)">
                <option v-if="placeholderVisible" :disabled="!placeholderSelectable" :value="placeholderValue">{{placeholderTranslate ? $t(placeholder) : placeholder}}</option>
                <option v-for="(option, index) in options" :value="index"
                    :selected="option.selected"
                    :disabled="option.disabled || false">{{translate ? $t(option.token) : option.token || option.value}}</option>
            </select>
        </label>
    </div>
</template>

<script>
export default {
    name: "LabelledSelect",

    data() {return {
        selected: null,
        placeholderValue: 'placeholder'
    };},

    props: {
        options: {
            required: true,
            type: Array
        },

        modelValue: {
            required: false
        },

        placeholder: {
            required: false,
            type: String,
            default: 'token.select_one'
        },

        placeholderSelectable: {
            required: false,
            type: Boolean,
            default: false
        },

        placeholderTranslate: {
            required: false,
            type: Boolean,
            default: true
        },

        placeholderVisible: {
            required: false,
            type: Boolean,
            default: true
        },

        translate: {
            required: false,
            type: Boolean,
            default: true
        }
    },

    methods: {
        changed(event) {
            this.$emit('changed', event.target.value === this.placeholderValue ?
                null :
                this.options[event.target.value]
            );
        }
    }
}
</script>
