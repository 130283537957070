<template>
    <span :class="['icon', 'core-' + icon]" role="img" :aria-label="ariaLabel"><slot></slot></span>
</template>

<script>
export default {
    name: "icon",
    props: {
        icon: {
            required: true,
            type: String
        },
        ariaLabel: {
            required: true,
            type: String,
        }
    }
}
</script>
