<template>
    <div class="page-guild-manage-members">
        <div>Members</div>
        <ul>
            <li class="d-flex" v-for="User in filteredGuildMembers">
                <div class="mx-3"><router-link-user :user="User"></router-link-user></div>
                <button v-if="Guild.meta.isOwner" class="mx-3" @click="discharge(User)">Discharge</button>
            </li>
        </ul>
    </div>
</template>

<script>

import RouterLinkUser from "../../../common/components/links/RouterLinkUser.vue";

export default {
    components: {RouterLinkUser},
    inject: ['Guild'],
    emits: ['membershipRequest', 'membershipRequest'],

    data: function() {return {

    };},

    computed: {
        filteredGuildMembers() {
            let members = {};

            Object.keys(this.Guild.data.users).forEach((uuid) => {
                if(uuid === this.$user.uuid) {
                    return;
                }

                let User = this.Guild.data.users[uuid];

                members[User.uuid] = User;
            });

            return members;
        },
    },

    methods: {
        discharge(User) {
            let url = this.Guild.meta.urls.membership.discharge.replace(':guild_uuid', this.Guild.uuid);

            axios.post(url, {
                user_uuid: User.uuid
            })
                .then((response) => {
                    if(response.data.accepted) {
                        this.$emit('membershipStatus', 'discharge', User);
                    }
                })
                .catch((error) => {
                    console.error('Fetch error', error);
                });
        },
    }
}
</script>
