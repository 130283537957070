import {RedirectRoute, Route} from "../common/classes/RouteTypes";
import User from "../common/plugins/User";
import {useModelsStore} from "../common/stores/Models";

const UserPlugin = new User();

export default UserPlugin.Model ? [
    new RedirectRoute({path: '/', name: 'index', redirect: {name: 'timeline'}, hidden: true}),

    new Route({path: '/users/:uuid', hidden: true,
        component: () => import('../authenticated/pages/user/Frame'),
        children: [
            new Route({
                path: '', label: 'User view', name: 'user.view',
                component: () => import('../authenticated/pages/user/View')
            }),
            new Route({
                path: 'manage', label: 'User manage', name: 'user.manage', permissions: [(User, options) => {
                    return User.isLoggedIn() && User.data.uuid === options.route.to.params.uuid;
                }],
                component: () => import('../authenticated/pages/user/Manage')
            })
        ]
    }),

    new Route({path: '/user/logout', label: 'Logout', name: 'user.logout', roles: [(User) => User.isLoggedIn()], hidden: true,
        component: () => import('../authenticated/pages/logout/Frame')}),

    new Route({path: '/guilds/:uuid', hidden: true,
        component: () => import('../authenticated/pages/guild/Frame'),
        children: [
            new Route({
                path: '', label: 'Guild view', name: 'guild.view',
                component: () => import('../authenticated/pages/guild/View')
            }),
            new Route({
                path: 'manage', label: 'Guild manage', name: 'guild.manage', permissions: [(User, options) => {
                    const ModelsStore = useModelsStore();
                    let Guild = ModelsStore.get(options.route.to.params.uuid);
                    return Guild.meta.isOwner;
                }],
                component: () => import('../authenticated/pages/guild/Manage')
            }),
            new Route({
                path: 'members', label: 'Guild members', name: 'guild.members',
                component: () => import('../authenticated/pages/guild/Members')
            }),
            new Route({
                path: 'applicants', label: 'Guild applicants', name: 'guild.manage.applicants', permissions: [(User, options) => {
                    const ModelsStore = useModelsStore();
                    let Guild = ModelsStore.get(options.route.to.params.uuid);
                    return Guild.meta.isOwner;
                }],
                component: () => import('../authenticated/pages/guild/Applicants')
            })
        ]
    }),

    new Route({
        path: '/timeline', label: 'timeline', name: 'timeline',
        component: () => import('../authenticated/pages/timeline/Frame')
    })
] : [];
