<template>
    <input type="submit" :value="label" :disabled="disabled" @click="action($event)">
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: 'Submit',

    props: {
        label: {
            required: true,
            type: String
        },
        action: {
            required: true,
            type: Function
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        }
    }
})
</script>
