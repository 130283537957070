<template>
    <router-link :to="{name: 'guild.view', params: { uuid: guild.data.uuid }}">{{guild.data.name}}</router-link>
</template>

<script>

export default {
    name: "RouterLinkGuild",

    props: {
        guild: {
            type: Object,
            required: true
        }
    }
}
</script>
