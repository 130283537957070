class BaseRouteClass {
    constructor(parameters) {
        this.meta = {
            hidden: Boolean(parameters.hidden ?? false),
            roles: parameters.roles ?? [],
            permissions: parameters.permissions ?? [],
            label: parameters.label ?? '## ERROR: NO LABEL ##',
            params: parameters.params ?? null
        };
    }

    resolveParams() {
        let resolved = {};
        if(this.params) {
            Object.keys(this.params).forEach((key) => {
                let value = this.params[key];

                resolved[key] = typeof value === 'function' ? value() : value;
            });
        }

        return resolved;
    }
}
class Route extends BaseRouteClass {
    constructor(parameters) {
        super(parameters);
        this.name = parameters.name ?? null;
        this.path = parameters.path ?? null;
        this.component = parameters.component ?? null;
        this.children = parameters.children ?? null;
        this.beforeResolve = parameters.beforeResolve ?? null;
        this.beforeEnter = parameters.beforeEnter ?? null;
    }
}

class RedirectRoute extends BaseRouteClass{
    constructor(parameters) {
        super(parameters);
        this.name = parameters.name ?? null;
        this.path = parameters.path ?? null;
        this.redirect = parameters.redirect;
    }
}

export {
    Route,
    RedirectRoute
};
